<script setup>
import VHtml from "../../components/VHtml.vue";
import { computed } from "vue";
import { capitalize } from "../../helpers";

const props = defineProps({
  instance: {
    type: Object,
    required: true,
  },
});

const { section, message, cta, cta_text, cta_position } = props.instance;

const regex = /^(.+)(%cta%)(.+)$/;

const before = computed(() => {
  let splitMessage;

  if (cta_position !== "inline") {
    return;
  }

  if ((splitMessage = regex.exec(message)) !== null) {
    return splitMessage[1];
  }

  return console.error("De variabele %cta% voor het plaatsen van de cta_text is niet gevonden.");
});

const after = computed(() => {
  let splitMessage;

  if (cta_position !== "inline") {
    return;
  }

  if ((splitMessage = regex.exec(message)) !== null) {
    return splitMessage[3];
  }

  return null;
});
</script>

<template>
  <p>
    <b v-if="section">{{ capitalize(section) }}&nbsp;</b>
    <template v-if="cta_position === 'inline'">
      {{ before }}
      <a href="javascript:;" @click="cta">{{ cta_text }}</a>
      {{ after }}
    </template>
    <template v-else>
      <v-html :html="message" />
      <a v-if="cta" href="javascript:;" @click="cta">&nbsp;{{ cta_text }}</a>
    </template>
  </p>
</template>
