export function useOptionProps() {
  return {
    first: {
      type: Boolean,
      default: false,
    },
    last: {
      type: Boolean,
      default: false,
    },
    section: {
      type: String,
      required: true,
    },
    choices: {
      type: Array,
      default: [],
    },
    divider: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    tooltipTitle: {
      type: String,
      default: null,
    },
    tooltipContent: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    iconType: {
      type: String,
      default: "regular",
    },
    iconClass: {
      type: String,
      default: "#h-[17px] #w-[19px] #relative #top-[2px]",
    },
    iconColor: {
      type: String,
      default: "#38CCFF",
    },
    iconTitle: {
      type: String,
      default: null,
    },
    iconContent: {
      type: String,
      default: null,
    },
    showPriceDiff: {
      type: Boolean,
      default: false,
    },
    updateUrl: {
      type: Boolean,
      default: true,
    },
  };
}

export function useRadioOptionProps() {
  return {
    ...useOptionProps(),
    showPriceDiff: {
      type: Boolean,
      default: true,
    },
    initial: {
      type: Boolean,
      default: false,
    },
  };
}
export function useCheckboxOptionProps() {
  return {
    ...useOptionProps(),
    showPriceDiff: {
      type: Boolean,
      default: true,
    },
    initial: {
      type: Boolean,
      default: false,
    },
  };
}
export function useIntegerOptionProps() {
  return {
    ...useOptionProps(),
    initial: {
      type: Number,
      default: null,
    },
  };
}
export function useTextAreaOptionProps() {
  return {
    ...useOptionProps(),
    initial: {
      type: String,
      default: null,
    },
  };
}
