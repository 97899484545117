import { getCurrentInstance, useSSRContext } from "vue";
import { FatalFormError } from "./errors";
import { useStore } from "vuex";
import { __, sprintf } from "./lang";

export function useCMS(name, check) {
  let disabled = false;
  let tooltipTitle = null;
  let tooltipContent = null;

  let source = null;
  if (import.meta.env.SSR) {
    const ctx = useSSRContext();
    source = ctx.order_settings;
  } else {
    source = window.order_settings;
  }

  if ("option_info" in source) {
    const option_info = source.option_info.find((i) => i.option.key === name);
    if (option_info) {
      tooltipTitle = option_info.title;
      tooltipContent = option_info.content;
    }
  }

  if ("disabled" in source) {
    const disabled_info = source.disabled.find((i) => i.option.key === name);
    if (disabled_info) {
      disabled = true;

      check((option) => {
        if (!option.isChecked()) {
          return;
        }

        throw new FatalFormError(disabled_info.error_text);
      });
    }
  }

  return { disabled, tooltipTitle, tooltipContent };
}

export function useChecks() {
  const isRequired = () => {
    return (option) => {
      if (option.isEmpty()) {
        throw new FatalFormError(__("Er moet een waarde ingevuld worden"));
      }
    };
  };

  const isOneOf = (choices) => {
    return (option) => {
      if (option.isEmpty()) {
        return;
      }

      if (!choices.includes(option.getValue())) {
        throw new FatalFormError(
          sprintf(__("Moet 1 van de volgende waardes zijn: %s"), choices.join(", ")),
        );
      }
    };
  };

  const isRequiredIfOther = (other) => {
    return (option, form) => {
      if (!form.isChecked(other) && option.isEmpty()) {
        return;
      }
      if (!form.isChecked(other) && !option.isEmpty()) {
        throw new FatalFormError(
          sprintf(__("Is alleen mogelijk als %s de waarde %s heeft"), other.key, other.value),
        );
      }
      if (option.isEmpty()) {
        throw new FatalFormError(
          sprintf(
            __("Er moet een waarde ingevuld worden als %s de waarde %s heeft"),
            other.key,
            other.value,
          ),
        );
      }
    };
  };

  const isInteger = () => {
    return (option) => {
      if (option.isEmpty()) {
        return;
      }

      if (!option.isInt()) {
        throw new FatalFormError(__("Moet een getal zijn"));
      }
    };
  };

  const isString = () => {
    return (option) => {
      if (option.isEmpty()) {
        return;
      }

      if (!option.isString()) {
        throw new FatalFormError(__("Moet tekst zijn"));
      }
    };
  };

  const isMinString = (min) => {
    return (option) => {
      if (option.isEmpty() || !option.isString()) {
        return;
      }

      if (option.getValue().length < min) {
        throw new FatalFormError(sprintf(__("Moet minimaal %s characters zijn"), min));
      }
    };
  };

  const isMaxString = (max) => {
    return (option) => {
      if (option.isEmpty() || !option.isString()) {
        return;
      }

      if (option.getValue().length > max) {
        throw new FatalFormError(sprintf(__("Mag maximaal %s characters zijn"), max));
      }
    };
  };

  const isMinInteger = (min) => {
    return (option) => {
      if (option.isEmpty() || !option.isInt()) {
        return;
      }

      if (option.getValue() < min) {
        throw new FatalFormError(sprintf(__("Moet minimaal %s zijn"), min));
      }
    };
  };

  const isMaxInteger = (max) => {
    return (option) => {
      if (option.isEmpty() || !option.isInt()) {
        return;
      }

      if (option.getValue() > max) {
        throw new FatalFormError(sprintf(__("Mag maximaal %s zijn"), max));
      }
    };
  };

  return {
    isRequired,
    isOneOf,
    isRequiredIfOther,
    isInteger,
    isMinInteger,
    isMinString,
    isMaxInteger,
    isString,
    isMaxString,
  };
}

export function useSetup(props) {
  const store = useStore();
  const option = getCurrentInstance().type;
  const name = option.__name;
  const { key, description, placeholder, label, value, key_width, key_height } = option;

  const check = (fn) => {
    store.commit("order/addOptionCheck", { option, section: props.section, fn });
  };

  return {
    option,
    check,
    name,
    key,
    description,
    placeholder,
    label,
    value,
    key_width,
    key_height,
  };
}
