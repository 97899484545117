<script setup>
import { computed, ref, useSSRContext } from "vue";
import BaseOption from "./info_options/BaseOption.vue";
import WeightOption from "./info_options/WeightOption.vue";

const active = ref("");

const options = computed(() => {
  let source = null;

  if (import.meta.env.SSR) {
    const ctx = useSSRContext();
    source = ctx.order_settings;
  } else {
    source = window.order_settings;
  }

  return source.order_info_options;
});

const findComponent = (option) => {
  switch (option.type.value) {
    case "BaseOption":
      return BaseOption;
    case "WeightOption":
      return WeightOption;
    default:
      throw new Error("Unknown order info item " + option.type.value);
  }
};
</script>

<template>
  <div class="box">
    <h4 class="widget-title">Extra info</h4>
    <ul class="media-list">
      <component
        :is="findComponent(option)"
        v-for="option in options"
        :id="option.id"
        :key="`option_${option.id}`"
        :title="option.title"
        :content="option.content.value"
        :instance="option"
        :active="active"
        @change="(id) => (active = id)"
      />
    </ul>
  </div>
</template>
