<script setup>
import { computed } from "vue";
import OptionRow from "../OptionRow.vue";
import { useCheckboxOptionProps } from "../composables/props";
import { useOrderForm } from "../../modules/orderModule";
import camelCase from "lodash.camelcase";
import { capitalize } from "../../helpers";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  ...useCheckboxOptionProps(),
});
const form = useOrderForm();

const id = computed(() => {
  return camelCase(props.name + "_" + props.value);
});

const classes = computed(() => {
  return {
    deactive: props.disabled,
  };
});

if (!import.meta.env.SSR) {
  form.addOption({
    name: props.name,
    value: props.value,
    type: "checkbox",
    showPriceDiff: props.showPriceDiff,
    updateUrl: props.updateUrl,
  });

  const paramsObject = Object.fromEntries(new URLSearchParams(window.location.search).entries());
  if (paramsObject[props.name]) {
    form.updateItem({
      name: props.name,
      value: paramsObject[props.name],
    });
  } else if (paramsObject[props.name] === undefined && props.initial) {
    form.updateItem({
      name: props.name,
      value: props.value,
    });
  }
}

const update = (event) => {
  if (event.target.checked) {
    form.updateItem({
      name: props.name,
      value: props.value,
    });
  } else {
    form.deleteItem(props.name);
  }
};

const checked = computed(() => {
  return form.getValue(props.name) === props.value;
});
</script>

<template>
  <OptionRow v-show="!hidden" v-bind="$props">
    <div class="checkbox fancy-checkbox">
      <input
        :id="id"
        type="checkbox"
        :value="value"
        :name="name"
        :checked="checked"
        @change="update"
      />
      <label class="fancy-control-ui" :for="id" />
      <label :class="classes" :for="id">{{ capitalize(label) }}</label>
    </div>
  </OptionRow>
</template>
