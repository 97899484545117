<script setup>
import { computed, inject, ref } from "vue";
import { useStore } from "vuex";
import SvgUse from "../components/SvgUse.vue";
import { __ } from "./composables/lang";
import { useOrderForm } from "../modules/orderModule";

const store = useStore();
const files = inject("files");
const form = useOrderForm();

const formatFileSize = (bytes) => {
  if (typeof bytes !== "number") {
    return "";
  }
  if (bytes >= 1000000000) {
    return (bytes / 1000000000).toFixed(1) + " GB";
  }
  if (bytes >= 1000000) {
    return (bytes / 1000000).toFixed(1) + " MB";
  }
  return (bytes / 1000).toFixed(1) + " KB";
};

const draggingFile = ref(null);
const draggingIndex = ref(null);
const targetIndex = ref(null);
const hovering = ref(null);

const rowStyles = (file, index, source) => {
  let classes = {};

  if (index === targetIndex.value) {
    classes["background-color"] = "#ddd";
  } else if (hovering.value === source) {
    classes["background-color"] = "#d9edf7";
  }

  return classes;
};

const selectedFileNames = computed(() => {
  let filenames = store.state.order.form.filenames ?? "";

  return filenames.split("/").filter((i) => i);
});

const allFiles = computed(() => {
  let sortedFiles = [...files.value].sort((a, b) => {
    if (selectedFileNames.value.includes(a.name) && selectedFileNames.value.includes(b.name)) {
      let indexA = selectedFileNames.value.indexOf(a.name);
      let indexB = selectedFileNames.value.indexOf(b.name);

      if (indexA > indexB) {
        return 1;
      }
      if (indexA < indexB) {
        return -1;
      }

      return 0;
    }
    if (!selectedFileNames.value.includes(a.name) && selectedFileNames.value.includes(b.name)) {
      return 1;
    }
    if (selectedFileNames.value.includes(a.name) && !selectedFileNames.value.includes(b.name)) {
      return -1;
    }

    if (a.filemtime < b.filemtime) {
      return 1;
    }
    if (a.filemtime > b.filemtime) {
      return -1;
    }

    return 0;
  });

  if (draggingIndex.value === null || targetIndex.value === null) {
    return sortedFiles;
  }

  const reordered = [...sortedFiles];
  const [draggedItem] = reordered.splice(draggingIndex.value, 1);
  reordered.splice(targetIndex.value, 0, draggedItem);

  return reordered;
});

const showUploadRow = computed(() => {
  if (selectedFileNames.value.length > 1) {
    return false;
  }

  if (selectedFileNames.value.length === 1 && hovering.value) {
    return draggingFile.value.name === selectedFileNames.value[0];
  }

  return selectedFileNames.value.length === 0;
});

const showRow = (file, source) => {
  if (hovering.value && file.unique_id === draggingFile.value.unique_id) {
    return hovering.value === source;
  }

  if (source === "saved") {
    return !selectedFileNames.value.includes(file.name);
  }
  if (source === "selected") {
    return selectedFileNames.value.includes(file.name);
  }

  throw new Error("unknown file source");
};

const onDragStart = (file, index, source, event) => {
  event.dataTransfer.dropEffect = "move";
  event.dataTransfer.effectAllowed = "move";

  setTimeout(() => {
    draggingIndex.value = index;
    hovering.value = source;
    draggingFile.value = file;
  }, 1);
};

const onDragOver = (file, index, source) => {
  if (draggingFile.value === null) {
    return;
  }

  hovering.value = source;
  targetIndex.value = index;
};

const onUploaderDragOver = () => {
  hovering.value = "selected";
  targetIndex.value = 0;
};

const onDragEnd = () => {
  hovering.value = null;
  draggingIndex.value = null;
  targetIndex.value = null;
  draggingFile.value = null;
};

const onDrop = (target) => {
  if (draggingFile.value === null) {
    return;
  }

  if (target === "selected") {
    const selectedFiles = allFiles.value.filter((file) => {
      if (selectedFileNames.value.includes(file.name)) {
        return true;
      }
      if (file.unique_id === draggingFile.value.unique_id) {
        return true;
      }

      return false;
    });
    const fileNames = selectedFiles
      .map((file) => {
        return file.name;
      })
      .join("/");

    form.updateItem({
      name: "filenames",
      value: fileNames,
    });
  } else if (target === "saved") {
    removeFromSelected(draggingFile.value);
  }
};

const removeFromSelected = (fileToRemove) => {
  if (!selectedFileNames.value.includes(fileToRemove.name)) {
    return;
  }

  const selectedFiles = allFiles.value.filter((file) => {
    if (selectedFileNames.value.includes(file.name)) {
      return true;
    }

    return false;
  });

  const fileNames = selectedFiles
    .filter((file) => {
      return file.unique_id !== fileToRemove.unique_id;
    })
    .map((file) => {
      return file.name;
    })
    .join("/");

  form.updateItem({
    name: "filenames",
    value: fileNames,
  });
};
</script>

<template>
  <div id="fileupload" style="margin-top: 20px">
    <div class="row fileupload-buttonbar" style="">
      <div class="col-lg-7">
        <span class="btn btn-secondary fileinput-button tailwind">
          <svg-use id="plus" type="solid" class="#w-[17px] #h-[17px] #fill-white" />
          <span>{{ __("Upload bestand(en)") }}</span>
          <input type="file" name="files[]" multiple="" translate="no" />
        </span>
        <span class="fileupload-process"></span>
      </div>
    </div>

    <table class="table document_list" style="">
      <thead>
        <tr>
          <th colspan="5">{{ __("Geselecteerde documenten") }}</th>
          <th colspan="5"></th>
        </tr>
      </thead>
      <tbody class="selected_files" @drop="onDrop('selected')" @dragover.prevent @dragenter.prevent>
        <tr
          v-if="showUploadRow"
          class="template-download"
          :style="{ backgroundColor: hovering === 'selected' ? '#d9edf7' : null }"
          draggable="false"
          @dragover.prevent="onUploaderDragOver"
        >
          <td colspan="5">
            <div class="empty-box">
              <svg viewBox="-49 141 512 512" style="height: 36px; fill: #787878">
                <g>
                  <path
                    d="M36.03,362.25v98.67c0,33.64,27.36,61,61,61H335.7c33.64,0,61-27.36,61-61v-98.67c0,0,0-5-10.41-5c-11.5,0-11.59,5-11.59,5
                        v98.67c0,21.5-17.5,39-39,39H97.03c-21.5,0-39-17.5-39-39v-98.67c0,0,0.93-5-10.57-5C36.03,357.25,36.03,362.25,36.03,362.25z"
                  ></path>
                  <path d="M216.36,421.7"></path>
                  <path
                    d="M299.42,347.56c-8.08-8.08-11.62-4.55-11.62-4.55l-60.44,60.44V257.58c0,0,0.93-5-10.57-5c-11.43,0-11.43,5-11.43,5v145.86
                        l-60.43-60.43c0,0-3.54-3.54-11.62,4.55c-8.13,8.13-3.94,11.01-3.94,11.01l80.04,79.62c1.97,1.97,4.35,2.95,7.16,2.95
                        s5.2-0.98,7.16-2.95l79.62-79.62C303.36,358.57,307.55,355.69,299.42,347.56z"
                  ></path>
                </g>
              </svg>
            </div>
          </td>
        </tr>
        <template v-for="(file, index) in allFiles" :key="`selected_${file.unique_id}`">
          <tr
            v-if="showRow(file, 'selected')"
            class="template-download"
            :style="rowStyles(file, index, 'selected')"
            draggable="true"
            @dragstart="onDragStart(file, index, 'selected', $event)"
            @dragover.prevent="onDragOver(file, index, 'selected')"
            @dragend="onDragEnd"
          >
            <td width="1%" style="padding-left: 10px; padding-right: 0px; background: none"></td>
            <td width="7%">
              <div>
                <span class="preview">
                  <a
                    :title="file.name"
                    rel="lightbox[]"
                    style="float: left"
                    :href="file.preview_link"
                  >
                    <img
                      style="z-index: 200; max-height: 60px; max-width: 60px"
                      :src="file.preview_link"
                    />
                  </a>
                </span>
              </div>
            </td>
            <td width="75%">
              <p class="name">
                <a :href="file.download_link" target="_blank">
                  <span class="filename" translate="no">
                    {{ file.name }}
                  </span>
                </a>
              </p>
              <p class="datetime">{{ file.datetime }}</p>
            </td>
            <td width="8%" class="status" style="padding-top: 12px">
              <span class="size">
                {{ formatFileSize(file.size) }}
              </span>
            </td>
            <td width="9%" class="tailwind">
              <a
                href="javascript:;"
                class="btn-icon tailwind"
                style="float: right"
                @click.stop="removeFromSelected(file)"
              >
                <svg-use id="xmark" type="solid" class="#w-[20px] #h-[20px] #fill-[#636363]" />
              </a>
            </td>
          </tr>
        </template>
      </tbody>

      <!-- Uploaded files -->
      <thead class="files-head" style="display: table-header-group">
        <tr>
          <th colspan="5">
            <span>{{ __("Opgeslagen documenten") }}</span>
            <input
              id="file_search_input"
              type="input"
              class="form-control form-control-search"
              :placeholder="__('Zoek bestand')"
              style="display: inline-block"
            />
          </th>
        </tr>
      </thead>
      <tbody class="files" @drop="onDrop('saved')" @dragover.prevent @dragenter.prevent>
        <template v-for="(file, index) in allFiles" :key="`saved_${file.unique_id}`">
          <tr
            v-if="showRow(file, 'saved')"
            class="template-download"
            :style="rowStyles(file, index, 'saved')"
            draggable="true"
            @dragstart="onDragStart(file, index, 'saved', $event)"
            @dragover.prevent="onDragOver(file, index, 'saved')"
            @dragend="onDragEnd"
          >
            <td width="1%" style="padding-left: 10px; padding-right: 0px; background: none"></td>
            <td width="7%">
              <div>
                <span class="preview">
                  <a
                    :title="file.name"
                    rel="lightbox[]"
                    style="float: left"
                    :href="file.preview_link"
                  >
                    <img
                      style="z-index: 200; max-height: 60px; max-width: 60px"
                      :src="file.preview_link"
                    />
                  </a>
                </span>
              </div>
            </td>
            <td width="75%">
              <p class="name">
                <a :href="file.download_link" target="_blank">
                  <span class="filename" translate="no">
                    {{ file.name }}
                  </span>
                </a>
              </p>
              <p class="datetime">{{ file.datetime }}</p>
            </td>
            <td width="8%" class="status" style="padding-top: 12px">
              <span class="size">
                {{ formatFileSize(file.size) }}
              </span>
            </td>
            <td width="calc(9%-7px)" class="tailwind">
              <a href="javascript:;" class="btn-icon tailwind" style="float: right">
                <svg-use id="trash" type="solid" class="#w-[17px] #h-[17px] #fill-[#636363]" />
              </a>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
