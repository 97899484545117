<script setup>
import { onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import LoadingBar from "./LoadingBar.vue";
import { __ } from "../order/composables/lang.js";
import SvgUse from "../components/SvgUse.vue";

const props = defineProps({
  errors: {
      type: Array,
      default: []
  }
});

const store = useStore();

const previewHeight = ref(260);
const canvasWidthLow = ref(906);
const canvasCssWidthLow = ref(453);
const canvasWidthHigh = ref(2700);
const canvasCssWidthHigh = ref(1350);

const numberOfFiles = ref(0);
const numberOfPages = ref(0);

watch(() => props.errors, (errors) => {
  window.emitter.emit("show-hide-preview", errors.length ? "hide" : "show");
})
</script>
<template>
  <div id="preview-holder" class="dropzone" data-show="0">
    <div id="live-preview" class="preview-first-load" style="display: none;">
      <div id="preview-slides-hover"></div>
      <div id="preview-dropzone">
          <div class="inner-dropzone"></div>
      </div>
      <div id="preview-background">
        <div id="preview-slides" :style="{height: previewHeight+'px'}">
          <canvas id="preview-overlay-middle" class="preview-canvas" :width="canvasWidthLow" :height="canvasWidthLow" :style="{width: canvasCssWidthLow+'px'}"></canvas>
          <canvas id="preview-overlay-first" class="preview-canvas" :width="canvasWidthLow" :height="canvasWidthLow" :style="{width: canvasCssWidthLow+'px'}"></canvas>
          <canvas id="preview-overlay-second" class="preview-canvas" :width="canvasWidthLow" :height="canvasWidthLow" :style="{width: canvasCssWidthLow+'px'}"></canvas>
          <canvas id="preview-overlay-second-last" class="preview-canvas" :width="canvasWidthLow" :height="canvasWidthLow" :style="{width: canvasCssWidthLow+'px'}"></canvas>
          <canvas id="preview-overlay-last" class="preview-canvas" :width="canvasWidthLow" :height="canvasWidthLow" :style="{width: canvasCssWidthLow+'px'}"></canvas>
          <canvas id="preview-overlay-spine" class="preview-canvas" :width="canvasWidthLow" :height="canvasWidthLow" :style="{width: canvasCssWidthLow+'px'}"></canvas>
          <canvas id="preview-overlay-all" class="preview-canvas" :width="canvasWidthLow" :height="canvasWidthLow" :style="{width: canvasCssWidthLow+'px'}"></canvas>
          <canvas id="preview-overlay-extra" class="preview-canvas" :width="canvasWidthLow" :height="canvasWidthLow" :style="{width: canvasCssWidthLow+'px'}"></canvas>
          <canvas id="preview-overlay-middle-high" class="preview-canvas" :width="canvasWidthHigh+'px'" :height="canvasWidthHigh+'px'" :style="{width: canvasCssWidthHigh+'px'}"></canvas>
          <canvas id="preview-overlay-first-high" class="preview-canvas" :width="canvasWidthHigh+'px'" :height="canvasWidthHigh+'px'" :style="{width: canvasCssWidthHigh+'px'}"></canvas>
          <canvas id="preview-overlay-second-high" class="preview-canvas" :width="canvasWidthHigh+'px'" :height="canvasWidthHigh+'px'" :style="{width: canvasCssWidthHigh+'px'}"></canvas>
          <canvas id="preview-overlay-second-last-high" class="preview-canvas" :width="canvasWidthHigh+'px'" :height="canvasWidthHigh+'px'" :style="{width: canvasCssWidthHigh+'px'}"></canvas>
          <canvas id="preview-overlay-last-high" class="preview-canvas" :width="canvasWidthHigh+'px'" :height="canvasWidthHigh+'px'" :style="{width: canvasCssWidthHigh+'px'}"></canvas>
          <canvas id="preview-overlay-all-high" class="preview-canvas" :width="canvasWidthHigh+'px'" :height="canvasWidthHigh+'px'" :style="{width: canvasCssWidthHigh+'px'}"></canvas>
          <canvas id="preview-overlay-extra-high" class="preview-canvas" :width="canvasWidthHigh+'px'" :height="canvasWidthHigh+'px'" :style="{width: canvasCssWidthHigh+'px'}"></canvas>
          <canvas id="preview-overlay-spine-high" class="preview-canvas" :width="canvasWidthHigh+'px'" :height="canvasWidthHigh+'px'" :style="{width: canvasCssWidthHigh+'px'}"></canvas>
          <canvas id="preview-product" data-first-load="1" data-docpage="1" data-last="0" :data-width="canvasWidthLow" data-active="0" :data-total="numberOfPages" :data-count="numberOfFiles" :width="canvasWidthLow+'px'" height="260px" :style="{width: canvasCssWidthLow+'px'}"></canvas>
          <canvas id="preview-product-high" :data-width="canvasWidthHigh" :width="canvasWidthHigh+'px'" :height="canvasWidthHigh+'px'" :style="{width: canvasCssWidthHigh+'px'}"></canvas>
          <canvas id="preview-overlay-dimensions" class="preview-canvas" :width="canvasWidthLow" :height="canvasWidthLow" :style="{width: canvasCssWidthLow+'px'}"></canvas>
        </div>
        <div id="preview-loading">
          <LoadingBar />
        </div>
      </div>
      <div id="preview-spine-editor">
        <a href="#" class="spine-editor-stop">Stop kleur kiezer</a>
      </div>
      <div id="preview-nav">
        <span style="position: absolute; bottom: 5px; left: 6px; height: 36px; width: 36px;" title="{{ __('Sleep hier je bestand') }}" class="has-tooltip" data-placement="bottom">
          <svg
id="Capa_1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="-49 141 512 512" style="enable-background:new -49 141 512 512; fill: #505050;" xml:space="preserve">
          <desc>{{ __('Sleep hier je bestand') }}</desc>
          <g>
              <path
d="M36.03,362.25v98.67c0,33.64,27.36,61,61,61H335.7c33.64,0,61-27.36,61-61v-98.67c0,0,0-5-10.41-5c-11.5,0-11.59,5-11.59,5
                  v98.67c0,21.5-17.5,39-39,39H97.03c-21.5,0-39-17.5-39-39v-98.67c0,0,0.93-5-10.57-5C36.03,357.25,36.03,362.25,36.03,362.25z"/>
              <path d="M216.36,421.7" />
              <path d="M 299.42 347.56 C 291.34 339.48 287.8 343.01 287.8 343.01 L 227.36 403.45 L 227.36 257.58 C 227.36 257.58 228.29 252.58 216.79 252.58 C 205.36 252.58 205.36 257.58 205.36 257.58 L 205.36 403.44 L 144.93 343.01 C 144.93 343.01 141.39 339.47 133.31 347.56 C 125.18 355.69 129.37 358.57 129.37 358.57 L 209.41 438.19 C 211.38 440.16 213.76 441.14 216.57 441.14 C 219.38 441.14 221.77 440.16 223.73 438.19 L 303.35 358.57 C 303.36 358.57 307.55 355.69 299.42 347.56 Z" transform="matrix(-1, 0, 0, -1, 432.72879, 693.720016)"></path>
          </g>
          </svg>
        </span>
        <p style="display: inline;">
            <a id="preview-first" alt="Eerste pagina" :title="__('Eerste pagina')"><svg-use id="angles-left" type="solid" width="15" height="20" fill="currentColor" /></a>
            <a id="preview-prev" alt="Vorige pagina" :title="__('Vorige pagina')"><svg-use id="angle-left" type="solid" width="9" height="20" fill="currentColor" /></a>
            <input id="preview-page" type="text" value="1" tabIndex="-1" /> / <span id="preview-page-total">1</span>
            <a id="preview-next" alt="Volgende pagina" :title="__('Volgende pagina')"><svg-use id="angle-right" type="solid" width="9" height="20" fill="currentColor" /></a>
            <a id="preview-last" alt="Laatste pagina" :title="__('Laatste pagina')"><svg-use id="angles-right" type="solid" width="15" height="20" fill="currentColor" /></a>
            <a id="preview-screenshot" style="display: none;">{{ __('Screenshot') }}</a>
        </p>
      </div>
      <div id="preview-zoom-holder" style="position:absolute; top:0; left:-483px; width: 463px; height: 463px; background: #e3e3e3; border: 1px solid #636363; overflow: hidden; display: none;">
          <div id="preview-zoom-loading">
            <LoadingBar />
          </div>
          <div id="preview-zoom-disclaimer">{{ __('Kleur en resolutie kunnen afwijken. Wij gebruiken het originele document voor de productie.') }}</div>
          <canvas id="preview-zoom" :width="canvasWidthHigh+'px'" :height="canvasWidthHigh+'px'" :style="{width: canvasCssWidthHigh+'px'}"></canvas>
      </div>
      <div id="preview-loader">
          <div id="preview-loadbar"></div>
      </div>
    </div>
  </div>
</template>
