<script setup>
import { __ } from "../composables/lang";
import { useChecks, useCMS, useSetup } from "../composables/option";
import TextareaField from "../fields/TextareaField.vue";

defineOptions({
  key: "comment",
  label: "Opmerking",
  placeholder: __("plaats hier je opmerking, aanwijzing of onzekerheid"),
});
const props = defineProps({
  initial: {
    type: String,
    default: null,
  },
});
const { check, name, key, placeholder } = useSetup(props);
const { disabled } = useCMS(name, check);
const { isString, isMaxString, isMinString } = useChecks();

const max = 1000;

check(isString());
check(isMinString(1));
check(isMaxString(max));
</script>

<template>
  <tbody>
    <TextareaField
      v-bind="$props"
      :disabled="disabled"
      :section="''"
      :name="key"
      :placeholder="placeholder"
      :max="max"
    />
  </tbody>
</template>
