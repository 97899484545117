<script setup>
import { onMounted, onUnmounted, provide, ref, watch } from "vue";
import { useEmitter } from "../order/composables/emitter";
import { useDirectives } from "../order/composables/directives";
import { useStore } from "vuex";
import WetransferDelivery from "./WetransferDelivery.vue";
import EmailDelivery from "./EmailDelivery.vue";
import UploadDelivery from "./UploadDelivery.vue";
import DeliveryConditions from "./DeliveryConditions.vue";
import { __ } from "./composables/lang";

const store = useStore();
const { on, off } = useEmitter();
const { vOutsideClick } = useDirectives();

const delivery_method = ref("upload");
const files = ref([]);

// little trick to prevent closing popup on hot reloads
const open = ref(document.body.classList.contains("lightbox-open"));
watch(open, (newValue) => {
  if (newValue) {
    document.body.classList.add("lightbox-open");
  } else {
    document.body.classList.remove("lightbox-open");
  }
});

const createLegacyOverlay = () => {
  if (document.getElementById("legacyLightboxOverlay") === null) {
    var overlay = document.createElement("div");
    overlay.id = "legacyLightboxOverlay";
    document.body.appendChild(overlay);
  }
};

onMounted(() => {
  on("open-uploader", () => {
    createLegacyOverlay();
    open.value = true;
  });
});

onUnmounted(() => {
  off("open-uploader");
});

watch(
  () => store.state.order.form.customer_id,
  async (newValue) => {
    try {
      console.log("AJAX call: boot");
      let url = store.getters.route("boot") + "?customerID=" + newValue;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      if (!response.ok) {
        await response.text();
        files.value = [];
      } else {
        files.value = (await response.json()).files;
      }
    } catch (error) {
      files.value = [];
      console.error(error);
    }
  },
  { immediate: true },
);

provide("files", files);

watch(files, (newValue) => {
  console.log(newValue);
});
</script>

<template>
  <div v-if="open" class="full_width docselect-lightbox lightbox">
    <div v-outside-click="() => (open = false)" class="grid_8 grid_10 absolute">
      <div id="uploadmodule">
        <div class="tab-content">
          <div id="upload" role="tabpanel" class="tab-pane active">
            <h1 id="article" style="float: left">{{ __("Voeg je document toe") }}</h1>

            <table class="tables calculate" cellpadding="0" cellspacing="0" width="80%">
              <thead>
                <tr>
                  <th width="50%">
                    <span class="article_id" style=""></span>{{ __("Aanlevermethode") }}
                  </th>
                  <th>{{ __("Aanlevervoorwaarden") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width="40%" style="vertical-align: top" class="select_options">
                    <div class="radio fancy-radio">
                      <input v-model="delivery_method" type="radio" value="upload" />
                      <label class="fancy-control-ui" @click="delivery_method = 'upload'"></label>
                      <label @click="delivery_method = 'upload'">
                        {{ __("Upload & selecteer (tot 500mb)") }}
                      </label>
                    </div>
                    <div class="radio fancy-radio">
                      <input v-model="delivery_method" type="radio" value="email" />
                      <label class="fancy-control-ui" @click="delivery_method = 'email'"></label>
                      <label @click="delivery_method = 'email'">
                        {{ __("Via de mail (tot 25mb)") }}
                      </label>
                    </div>
                    <div class="radio fancy-radio">
                      <input v-model="delivery_method" type="radio" value="wetransfer" />
                      <label
                        class="fancy-control-ui"
                        @click="delivery_method = 'wetransfer'"
                      ></label>
                      <label @click="delivery_method = 'wetransfer'">
                        {{ __("WeTransfer (tot 2gb)") }}
                      </label>
                    </div>
                  </td>
                  <td width="60%">
                    <div>
                      <DeliveryConditions />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="padding: 0px">
                    <div
                      v-if="delivery_method === 'email'"
                      id="addFileMethodEmail_div"
                      class="unfold"
                    >
                      <EmailDelivery />
                    </div>

                    <div
                      v-if="delivery_method === 'wetransfer'"
                      id="addFileMethodWetransfer_div"
                      class="unfold"
                    >
                      <WetransferDelivery />
                    </div>
                    <div
                      v-if="delivery_method === 'upload'"
                      id="addFileMethodUpload_div"
                      class="unfold"
                    >
                      <UploadDelivery />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
