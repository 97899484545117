import { useStore } from "vuex";
import { useForm } from "./default-form-options";
import { watch } from "vue";
import { __ } from "../order/composables/lang";

export function useCustomerForm(data, extraOptions) {
  const store = useStore();

  const form = useForm(
    {
      company: store.getters.user?.company || "",
      vat_number: store.getters.user?.vat_number || "",
      contact_type: store.getters.customerType ?? "particulier",
      name_contact: store.getters.user?.name || "",
      email: store.getters.user?.email || "",
      telephone: store.getters.user?.telephone || "",
      zipcode: store.getters.user?.zipcode || "",
      city: store.getters.user?.city || "",
      street: store.getters.user?.street || "",
      streetnumber: store.getters.user?.streetnumber || "",
      country: store.getters.user?.country || "",
      keep_updated: store.getters.user?.keep_updated || false,

      ...data,
    },
    {
      isCompany: false,
      fromEurope: true,
      deleteCompany: false,
      validateVatNumberNow: false,
      pay_on_account: store.getters.user?.pay_on_account || 0,
      pay_on_account_requested: store.getters.user?.pay_on_account_requested || false,
      submitting: {
        details: false,
        label: false,
        password: false,
        pay_on_account: false,
      },
      async update(url) {
        form.submitting.details = true;
        form.clearAllErrors();

        if (form.deleteCompany === true) {
          form.company = "";
          form.vat_number = "";
          form.email4invoice = "";
          form.showInvoiceMail = false;
          form.kvk_number = null;
          form.pay_on_account = 0;
          form.pay_on_account_requested = false;
        }

        if (form.contact_type === "bedrijf" && !form.fromEurope) {
          form.vat_number = "";
        }

        if (form.contact_type !== "bedrijf") {
          form.vat_number = "";
          form.company = "";
        }

        const response = await form.post(url);

        if (response.ok) {
          if (response.data.success && response.data.message) {
            setTimeout(() => {
              if (
                form.warnings["company"] &&
                (form.pay_on_account === 1 || form.pay_on_account_requested)
              ) {
                form.pay_on_account = 0;
                form.pay_on_account_requested = false;
                form.warnings["company"] = null;
              }
              form.warnings["kvk_number"] = null;
              form.submitting.details = false;
              form.isDirty = false;
            }, 500);
          }
        } else if (response.status === 422) {
          form.isDirty = false;
          form.submitting.details = false;
        } else {
          await response.text();
        }

        return response;
      },
      ...extraOptions,
    },
  );

  watch(
    [() => form.country, () => store.getters.countries],
    ([newCountry, newCountries]) => {
      if (newCountries && Object.keys(newCountries).length > 0 && newCountry) {
        form.fromEurope = newCountries[newCountry].from_eu;
      }

      if (newCountries && Object.keys(newCountries).length > 0 && !newCountry) {
        form.fromEurope = false;
      }
    },
    { immediate: true },
  );

  watch(
    () => form.contact_type,
    (newValue, oldValue) => {
      form.isCompany = newValue === "bedrijf";
      form.deleteCompany = oldValue === "bedrijf" && newValue !== "bedrijf";
    },
    { immediate: true },
  );

  watch(
    () => form.company,
    () => {
      form.warnings.company = null;
      form.warnings.kvk_number = null;

      if (!form.isDirty) {
        return;
      }

      if (form.pay_on_account !== 0 && form.pay_on_account !== 1) {
        return;
      }

      if (form.pay_on_account === 0 && !form.pay_on_account_requested) {
        return;
      }

      form.warnings.company = __(
        'Als je de bedrijfsnaam aanpast, zal je de betaalmethode "Bestellen op rekening" opnieuw moeten aanvragen.',
      );
    },
  );

  const getCountries = async () => {
    let response = await fetch(store.getters.route("countries"), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    });

    if (response.ok) {
      response = await response.json();
      store.commit("fillCountries", response);
    } else {
      await response.text();
    }
  };

  getCountries();

  if (store.getters.config("app.locale") === "nl" && !store.getters.user) {
    form.country = "NL";
  }

  return form;
}
