import isEqual from "lodash.isequal";
import { useStore } from "vuex";

export default {
  namespaced: true,
  state: () => ({
    initialized: false,
    tooltip: {
      open: false,
      title: "",
      content: "",
      anchor: null,
    },
    form: {},
    fileinfo: {},
    checks: [],
    options: [],
    calculation: {},
  }),
  mutations: {
    isInitialized(state) {
      state.initialized = true;
    },
    openTooltip(state, payload) {
      state.tooltip.title = payload.title;
      state.tooltip.content = payload.content;
      state.tooltip.anchor = payload.anchor;
      state.tooltip.open = true;
    },
    closeTooltip(state) {
      state.tooltip.open = false;
    },
    updateItem(state, payload) {
      if (state.initialized && state.form[payload.name] != payload.value) {
        console.log({ name: payload.name, from: state.form[payload.name], to: payload.value });
      }
      state.form[payload.name] = payload.value;
    },
    deleteItem(state, name) {
      if (state.initialized && state.form[name] !== undefined) {
        console.log({ name: name, from: state.form[name], to: undefined });
      }
      delete state.form[name];
    },
    setFileinfo(state, payload) {
      if (isEqual(payload, state.fileinfo)) {
        return;
      }

      Object.keys(state.fileinfo).forEach((key) => delete state.fileinfo[key]);
      Object.keys(payload).forEach((key) => (state.fileinfo[key] = payload[key]));
    },
    deleteFileinfo(state) {
      if (Object.keys(state.fileinfo).length === 0) {
        return;
      }

      Object.keys(state.fileinfo).forEach((key) => delete state.fileinfo[key]);
    },
    setPriceDifference(state, payload) {
      const option = state.options.find(
        (option) =>
          option.name === payload.name &&
          option.value === payload.value &&
          option.showPriceDiff === true,
      );

      if (option) {
        option.difference = payload.difference;
      }
    },
    clearPriceDifference(state) {
      Object.keys(state.options).forEach((key) => {
        if (state.options[key].showPriceDiff === true) {
          state.options[key]["difference"] = 0;
        }
      });
    },
    addOption(state, payload) {
      if (payload.showPriceDiff === true) {
        state.options.push({ ...payload, difference: 0 });
      } else {
        state.options.push({ ...payload });
      }
    },
    addOptionCheck(state, { option, section, fn }) {
      const duplicate = state.checks.find((item) => {
        if (option.key !== option.key) {
          return false;
        }
        if (item.section !== section) {
          return false;
        }
        if (item.fn.toString() !== fn.toString()) {
          return false;
        }

        return true;
      });

      if (duplicate) {
        return;
      }

      state.checks.push({ option, section, fn });
    },
    addProductCheck(state, fn) {
      const duplicate = state.checks.find((item) => {
        if (item.fn.toString() !== fn.toString()) {
          return false;
        }

        return true;
      });

      if (duplicate) {
        return;
      }

      state.checks.push({ fn });
    },
    setCalculation(state, payload) {
      if (isEqual(payload, state.calculation)) {
        return;
      }

      Object.keys(state.calculation).forEach((key) => delete state.calculation[key]);
      Object.keys(payload).forEach((key) => (state.calculation[key] = payload[key]));
    },
    clearCalculation(state) {
      if (Object.keys(state.calculation).length === 0) {
        return;
      }

      Object.keys(state.calculation).forEach((key) => delete state.calculation[key]);
    },
  },
  getters: {
    getValue:
      (state) =>
      (option, fallback = null) => {
        if (typeof option === "string") {
          return state.form[option] ?? fallback;
        }

        return state.form[option.key] ?? fallback;
      },
    getFileinfo: (state) => () => {
      return state.fileinfo;
    },
    getCalculation: (state) => () => {
      return state.calculation;
    },
    isChecked: (state, getters) => (option) => {
      if (!option.value) {
        return false;
      }

      return getters.getValue(option) === option.value;
    },
    isEmpty: (state, getters) => (option) => {
      return getters.getValue(option) === null || getters.getValue(option) === undefined;
    },
    isInt: (state, getters) => (option) => {
      const val = getters.getValue(option);

      return Number.isInteger(val) && val < Number.MAX_SAFE_INTEGER;
    },
    isString: (state, getters) => (option) => {
      const val = getters.getValue(option);

      return typeof val === "string";
    },
    getPriceDifference: (state) => (name, value) => {
      const option = state.options.find(
        (option) => option.name === name && option.value === value && option.showPriceDiff === true,
      );

      return option ? option.difference : null;
    },
  },
};

export function useOrderForm() {
  const store = useStore();

  return {
    getValue: store.getters["order/getValue"],
    getFileinfo: store.getters["order/getFileinfo"],
    getCalculation: store.getters["order/getCalculation"],
    isChecked: store.getters["order/isChecked"],
    isEmpty: store.getters["order/isEmpty"],
    isInt: store.getters["order/isInt"],
    isString: store.getters["order/isString"],
    getPriceDifference: store.getters["order/getPriceDifference"],
    isInitialized: () => store.commit("order/isInitialized"),
    updateItem: (payload) => store.commit("order/updateItem", payload),
    deleteItem: (name) => store.commit("order/deleteItem", name),
    openTooltip: (payload) => store.commit("order/openTooltip", payload),
    setFileinfo: (payload) => store.commit("order/setFileinfo", payload),
    deleteFileinfo: () => store.commit("order/deleteFileinfo"),
    addOption: (payload) => store.commit("order/addOption", payload),
    clearPriceDifference: () => store.commit("order/clearPriceDifference"),
    setPriceDifference: (payload) => store.commit("order/setPriceDifference", payload),
    addOptionCheck: (payload) => store.commit("order/addOptionCheck", payload),
    addProductCheck: (payload) => store.commit("order/addProductCheck", payload),
    setCalculation: (payload) => store.commit("order/setCalculation", payload),
    clearCalculation: (payload) => store.commit("order/clearCalculation", payload),
  };
}
