<script setup>
import { computed } from "vue";
import { useOptionProps } from "../composables/props";
import SvgUse from "../../components/SvgUse.vue";
import { useOrderForm } from "../../modules/orderModule";
import { capitalize } from "../../helpers";
import { __ } from "../composables/lang";
import { useEmitter } from "../composables/emitter";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  ...useOptionProps(),
});

const form = useOrderForm();
const { emit } = useEmitter();

const rowClasses = computed(() => {
  let classes = {
    "big-spacing-top": props.first,
    "border-bottom": props.divider && props.last,
  };
  classes[`tr_${props.name}`] = true;

  return classes;
});

const openUploader = () => {
  // TODO: scroll to top

  emit("open-uploader");
};
</script>

<template>
  <tr :class="rowClasses">
    <td>
      {{ first ? capitalize(section) : "" }}
    </td>
    <td colspan="2">
      <span class="docselect btn btn-primary" @click.stop="openUploader">
        <span class="tailwind">
          <svg-use
            id="file-plus"
            type="solid"
            class="#fill-current #w-[14px] #h-[17px] #mr-[5px]"
          ></svg-use>
        </span>
        <span>{{ __("Voeg toe") }}</span>
      </span>
    </td>
  </tr>
</template>
