<script setup>
import { computed, onMounted, ref, watch } from "vue";
import * as products from "./products";
import { FatalFormError, InfoFormWarning } from "./composables/errors";
import FatalError from "./messages/FatalError.vue";
import OrderPrices from "./OrderPrices.vue";
import OrderTooltip from "./OrderTooltip.vue";
import { useStore } from "vuex";
import SvgUse from "../components/SvgUse.vue";
import { useOrderForm } from "../modules/orderModule";
import debounce from "lodash.debounce";
import { __ } from "./composables/lang";
import InfoWarning from "./messages/InfoWarning.vue";
import OrderDeliveryTerms from "./OrderDeliveryTerms.vue";
import Preview from "../preview/Preview.vue";

const props = defineProps({
  product: {
    type: String,
    default: "",
  },
});

const form = useOrderForm();
const store = useStore();
const initialLoadingDone = ref(false);
const showDeliveryConditions = ref(false);

const component = computed(() => {
  for (const [, entry] of Object.entries(products)) {
    if (entry.slug === props.product) {
      return entry;
    }
    let aliases = entry.aliases ?? [];
    if (aliases.find((item) => item.slug === props.product)) {
      return entry;
    }
  }

  return null;
});

const title = computed(() => {
  for (const [, entry] of Object.entries(products)) {
    if (entry.slug === props.product) {
      return entry.title;
    }

    let aliases = entry.aliases ?? [];
    let alias = aliases.find((item) => item.slug === props.product);
    if (alias) {
      return alias.title;
    }
  }

  return null;
});

const messageComponent = (message) => {
  if (message instanceof FatalFormError) {
    return FatalError;
  }
  if (message instanceof InfoFormWarning) {
    return InfoWarning;
  }

  return null;
};

const errors = ref([]);
const warnings = ref([]);
const calculation = computed(() => {
  if (Object.keys(store.state.order.calculation).length === 0) {
    return null;
  }

  return store.state.order.calculation;
});

const sumbit = async () => {
  let data = null;

  try {
    console.log("AJAX call: article store");
    const response = await fetch(store.getters.route("orders.articles.store"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(store.state.order.form),
    });

    if (!response.ok) {
      await response.text();
      errors.value.push(new FatalFormError("Onbekend fout opgetreden"));

      return;
    }

    data = await response.json();
  } catch (error) {
    console.error(error);
    errors.value.push(new FatalFormError("Onbekend fout opgetreden"));

    return;
  }

  let errorsTemp = [];
  for (const error of data.errors) {
    if (error.type === "FatalFormError") {
      errorsTemp.push(new FatalFormError(error.message, error));
    }
  }
  errors.value = errorsTemp;

  console.log(data);
};

onMounted(() => {
  form.isInitialized();
});

watch(
  () => store.state.order.calculation.compare_specs_hash,
  (newValue, oldValue) => {
    if (!oldValue || !newValue) {
      return;
    }

    const existing = warnings.value.find((i) => i.key === "delivery_terms");
    if (existing) {
      return;
    }

    warnings.value.push(
      new InfoFormWarning(__("De %cta% zijn gewijzigd"), {
        key: "delivery_terms",
        cta_position: "inline",
        cta_text: __("aanlevervoorwaarden"),
        cta: () => (showDeliveryConditions.value = true),
      }),
    );
  },
);

watch(errors, () => {
  if (errors.value.length !== 0) {
    form.clearPriceDifference();
  }
});

watch(
  [store.state.order.form, store.state.order.fileinfo],
  debounce(async () => {
    if (!store.state.order.initialized) {
      return;
    }

    let errorsTemp = [];
    let warningsTemp = [];

    if (component.value === null) {
      errorsTemp.push(new FatalFormError("Onbekend", { key: "product", section: "Product" }));
    }
    console.log("Running all checks");
    for (const check of store.state.order.checks) {
      try {
        if (check.option) {
          check.fn(
            new Proxy(check.option, {
              get(target, prop) {
                if (form[prop] && typeof form[prop] === "function") {
                  return (...args) => form[prop](target, ...args);
                }
                return Reflect.get(...arguments);
              },
            }),
            form,
          );
        } else {
          check.fn(form, form.getFileinfo());
        }
      } catch (err) {
        if (err instanceof FatalFormError) {
          err.key = check.option?.key;
          err.section = check.section;
          errorsTemp.push(err);
        } else if (err instanceof InfoFormWarning) {
          err.key = check.option?.key;
          err.section = check.section;
          warningsTemp.push(err);
        } else {
          throw err;
        }
      }
    }

    errors.value = errorsTemp;
    warnings.value = warningsTemp;
  }, 10),
);

watch(
  store.state.order.form,
  debounce(async () => {
    if (!store.state.order.initialized || errors.value.length !== 0) {
      form.clearCalculation();
      return;
    }

    let formData = new URLSearchParams();
    for (const [key, value] of Object.entries(store.state.order.form)) {
      formData.append(key, value !== null ? value : "");
    }

    // TEMP fix because delivery conditions dont work without it
    if (!("size_custom_height" in store.state.order.form)) {
      formData.append("size_custom_height", "");
    }
    if (!("size_custom_width" in store.state.order.form)) {
      formData.append("size_custom_width", "");
    }

    try {
      console.log("AJAX call: calculate_article");
      const response = await fetch(window.LEGACY_URL + "/order/calculate_article.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: formData,
      });

      if (!response.ok) {
        await response.text();
        form.clearCalculation();
      } else {
        form.setCalculation(await response.json());
      }
    } catch (error) {
      console.error(error);
      form.clearCalculation();
    }
  }, 200),
);

watch(store.state.order.form, () => {
  if (!store.state.order.initialized) {
    return;
  }

  let params = { mode: "modern" };
  for (const [key, value] of Object.entries(store.state.order.form)) {
    const option = store.state.order.options.find((option) => option.name === key);
    if (option.updateUrl === true && value !== null && value !== undefined && value !== "") {
      params[key] = value;
    }
  }
  const checkboxes = store.state.order.options.filter((option) => option.type === "checkbox");
  for (const checkbox of checkboxes) {
    if (checkbox.name in params) {
      continue;
    }
    params[checkbox.name] = "";
  }

  const keys = Object.keys(params);
  const orderedKeys = ["mode", "product", "type"].filter((key) => keys.includes(key));
  const otherKeys = keys.filter((key) => !orderedKeys.includes(key)).sort();
  const sortedKeys = [...orderedKeys, ...otherKeys];

  const sortedParams = {};
  sortedKeys.forEach((key) => {
    sortedParams[key] = params[key];
  });

  const url = new URL(window.location.origin + window.location.pathname);
  for (const [key, value] of Object.entries(sortedParams)) {
    url.searchParams.set(key, value);
  }

  window.history.replaceState({}, "", url.href);
});

watch(
  store.state.order.form,
  debounce(async () => {
    if (!store.state.order.initialized || errors.value.length !== 0) {
      return;
    }

    let formData = new URLSearchParams();
    let index = 0;

    for (const [key, value] of Object.entries(store.state.order.form)) {
      formData.append(`form[${index}][name]`, key);
      formData.append(`form[${index}][value]`, value !== null ? value : "");
      index++;
    }

    const options = store.state.order.options.filter((option) => option.showPriceDiff === true);
    options.forEach((option, index) => {
      formData.append(`options[${index}][name]`, option.name);
      formData.append(`options[${index}][value]`, option.value);
    });

    try {
      console.log("AJAX call: calculate_multiple_articles");
      const response = await fetch(window.LEGACY_URL + "/order/calculate_multiple_articles.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: formData,
      });

      if (!response.ok) {
        form.clearPriceDifference();
        console.error(await response.text());

        return;
      }

      const prices = await response.json();
      for (const [key, item] of Object.entries(prices)) {
        const option = options[key];
        let difference = item.price_total - calculation.value.price_total;

        if (item.with_tax != 0) {
          difference =
            (item.price_total - calculation.value.price_total) * (1 + item.taxrate / 100);
        }
        if (difference == 77 || difference > 500 || difference < -500) {
          // uitzondering, dit betekent dat de prijs niet berekend kon worden
          difference = 0;
        }

        form.setPriceDifference({ name: option.name, value: option.value, difference });
      }
    } catch (error) {
      form.clearPriceDifference();
      console.error(error);

      return;
    }
  }, 200),
);

watch(
  store.state.order.form,
  debounce(async () => {
    if (!store.state.order.initialized) {
      return;
    }

    let formData = new URLSearchParams();
    formData.append("f", "getFileInfo");
    formData.append("filename", form.getValue("filenames", ""));
    formData.append("fullscan", 1);

    let index = 0;
    for (const [key, value] of Object.entries(store.state.order.form)) {
      formData.append("form_data[" + index + "][name]", key);
      formData.append("form_data[" + index + "][value]", value !== null ? value : "");
      index++;
    }

    const fileInfoFailed = () => {
      errors.value.push(
        new FatalFormError(
          __("Je documenten zijn goed ontvangen maar konden niet worden geanalyseerd."),
        ),
      );
      form.deleteFileinfo();
    };

    try {
      console.log("AJAX call: getfileinfo");
      const response = await fetch(window.LEGACY_URL + "/inc/ajax.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: formData,
      });

      if (!response.ok) {
        await response.text();

        return fileInfoFailed();
      }

      const data = await response.json();
      if (data?.error === "file error") {
        return fileInfoFailed();
      }

      if (form.getValue("default_doc") === 0) {
        form.setFileinfo(data);
      } else if (form.getValue("default_doc") === 1 && form.getFileinfo().length > 0) {
        form.deleteFileinfo();
      }
    } catch (error) {
      console.error(error);

      return fileInfoFailed();
    }

    if (initialLoadingDone.value == false) {
      initialLoadingDone.value = true;

      console.log("init preview");
      window.emitter.emit("init-preview");
    } else {
      console.log("refresh preview");
      window.emitter.emit("refresh-preview");
    }
  }, 200),
);
</script>

<template>
  <div class="container_12">
    <div class="custom_width content_page">
      <div class="page article article1">
        <div class="grid_9 article_header">
          <h1 v-if="component">
            {{ title }} {{ __("samenstellen") }}
            <a
              href="javascript:;"
              title="Wijzig product"
              class="prodselect lightboxbtn button grey no_arrow"
            >
              {{ __("wijzig") }}
            </a>
          </h1>
        </div>
        <div class="grid_9">
          <div class="grid_4 suffix_1 article_left">
            <form id="calcForm">
              <table class="cellspacing">
                <component :is="component" v-if="component" :product="product" />
              </table>
            </form>
            <OrderTooltip />
          </div>
          <div id="sticky-container" class="grid_4">
            <div id="follow-container">
              <div class="article_right">
                <div id="article_price_tabel">
                  <OrderPrices v-if="calculation" :calculation="calculation" />
                  <div id="add_to_cart">
                    <p v-if="!calculation" class="default-price-text mt-4">
                      {{ __("Prijs & leverdatum worden automatisch berekend") }}
                    </p>
                    <button
                      id="winkelmandje"
                      class="legacy-submit noproduct btn btn-secondary"
                      title="Voeg toe aan winkelmandje"
                      @click="sumbit"
                    >
                      <span class="tailwind">
                        <svg-use
                          id="cart-plus"
                          type="solid"
                          class="#fill-white #w-[20px] #h-[20px]"
                        ></svg-use>
                      </span>
                      {{ __("Bestel") }}
                    </button>
                  </div>
                </div>
                <Preview :errors="errors" />
              </div>
              <div class="fixed error-messages">
                <div v-if="errors.length > 0" class="grid_12 errors errors2" style="display: block">
                  <component
                    :is="messageComponent(error)"
                    v-for="error in errors"
                    :key="error.id"
                    :instance="error"
                  />
                </div>
                <div
                  v-if="warnings.length > 0"
                  class="grid_12 errors errors1"
                  style="display: block"
                >
                  <component
                    :is="messageComponent(warning)"
                    v-for="warning in warnings"
                    :key="warning.id"
                    :instance="warning"
                  />
                </div>
              </div>
              <OrderDeliveryTerms
                :open="showDeliveryConditions"
                class="page-legacy"
                @close="showDeliveryConditions = false"
              ></OrderDeliveryTerms>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.page-legacy .popover.right {
  left: unset !important;
}
@media (max-width: 767px) {
  .popover {
    min-width: 300px;
  }
}

.popover-content small {
  font-size: 12px;
}
.popover-content img {
  max-width: 100%;
}
</style>
