<script setup>
import { getCurrentInstance, watch } from "vue";
import HiddenField from "../fields/HiddenField.vue";
import { useStore } from "vuex";
import { useOrderForm } from "../../modules/orderModule";

defineOptions({
  key: "copies",
  type: "integer",
});
const props = defineProps({ initial: { type: [String, Number, Boolean], required: true } });
const { key, type } = getCurrentInstance().type;

const store = useStore();
const form = useOrderForm();

watch(store.state.order.fileinfo, ({ doc: { page_count = props.initial } = {} }) => {
  if (store.state.order.form[key] === page_count) {
    return;
  }

  form.updateItem({ name: key, value: page_count });
});
</script>

<template>
  <HiddenField :name="key" :type="type" :initial="initial" />
</template>
