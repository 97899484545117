import { sprintf as sprintfNative } from "sprintf-js";

export function __(key) {
  var translation = window.translations[key];
  if (translation === undefined) {
    return key;
  }
  return translation;
}

export function sprintf(...args) {
  return sprintfNative(...args);
}
