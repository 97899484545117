<script setup>
import { computed } from "vue";
import { useRadioOptionProps } from "../composables/props";
import RadioField from "../fields/RadioField.vue";
import TopcoatOption from "./TopcoatOption.vue";
import { useChecks, useCMS, useSetup } from "../composables/option";
import { useOrderForm } from "../../modules/orderModule";

import { __ } from "../composables/lang";

defineOptions({
  key: "papertype",
  value: "nevertear_125",
  label: __("polyester (125 µm)"),
});
const props = defineProps(useRadioOptionProps());
const { check, name, key, label, value } = useSetup(props);
const { disabled: cms_disabled, tooltipTitle, tooltipContent } = useCMS(name, check);
const { isRequired, isOneOf } = useChecks();
const form = useOrderForm();

check(isRequired());
check(isOneOf(props.choices));

const disabled = computed(() => {
  if (cms_disabled) {
    return true;
  }

  return form.isChecked(TopcoatOption);
});
</script>

<template>
  <RadioField
    v-bind="$props"
    :disabled="disabled"
    :tooltip-title="tooltipTitle"
    :tooltip-content="tooltipContent"
    :name="key"
    :value="value"
    :label="label"
  />
</template>
