<template>
  <div class="tailwind">
    <div class="#flex #justify-center">
      <FlashMessage ref="message" @done="message = false" />
    </div>

    <Modal :open="open" :fullscreen="true">
      <h2 class="#mb-4">Lees het bord op de muur goed!</h2>
      <p class="#mb-4">
        Doorloop alle stappen. Vink de belangrijkste stappen af rechts van het camerabeeld.
      </p>
      <Button text="Sluiten" @click="open = false" />
    </Modal>

    <div v-show="!message && !employee" class="#flex #flex-col #items-center #h-[500px]">
      <h3 class="#text-xl #font-serif #mt-3 #mb-10">Scan je polsbandje</h3>
      <svg-use id="nfc" type="regular" class="#h-32 #w-32 #fill-black" />
    </div>

    <div v-show="!message && employee && !photoTaken">
      <div class="#m-auto">
        <div class="#flex">
          <div class="#mt-[-8px] #flex-1 #max-w-80">
            <img
              class="#h-[80%] #min-w-max"
              src="/admin/images/photobooth-position-examples.png"
              alt="Ontwerp photobooth wel/niet"
            />
          </div>
          <div class="#w-full #flex-[2]">
            <div class="#h-24 #flex #justify-center #items-center #-mt-24">
              <p v-if="countingDown" class="#text-center #text-[60px] #text-brightcyan #font-bold">
                {{ countDown }}
              </p>
              <div v-else>
                <Button
                  text="Start timer"
                  :disabled="steps.find((step) => !step.completed)"
                  :disabled-styling="steps.find((step) => !step.completed)"
                  @click="startCountdown"
                />
              </div>
            </div>

            <div class="#relative #m-auto #w-[fit-content]">
              <div class="#relative">
                <video ref="video" class="#m-auto" @canplay="initCanvas">Stream unavailable</video>
                <canvas
                  id="myCanvas"
                  ref="canvasFaceFrame"
                  class="#absolute #top-0 #left-0 #pointer-events-none"
                ></canvas>
              </div>
            </div>

            <div class="#flex #space-x-4 #justify-center">
              <div v-for="photo of examplePhotos" :key="photo">
                <img
                  :src="photo.url"
                  :alt="photo.alt"
                  class="#m-auto"
                  onerror="this.onerror=null; this.src='/img/thumb-empty.png';"
                />
              </div>
            </div>
          </div>
          <div class="#ml-[30px] #flex #space-y-4 #flex-col #flex-[2] #max-w-[250px]">
            <div v-for="(step, index) in steps" :key="index" class="#flex #items-center #space-x-4">
              <button
                :class="step.completed ? '#bg-green-500 #text-white' : '#bg-gray-200 #text-black'"
                class="#px-4 #py-2 #rounded #mr-2"
                @click="toggleStep(index)"
              >
                <span v-if="step.completed">✔</span>
                <span v-else>✗</span>
              </button>

              <div
                class="#items-center"
                :class="step.completed ? '#text-gray-500' : ''"
                @click="toggleStep(index)"
              >
                <p class="#font-bold">{{ step.title }}</p>
                <p :class="step.completed ? '#line-through' : ''">{{ step.content }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="!message && employee && photoTaken" class="#mt-2">
      <div class="#flex #space-x-8">
        <div class="#space-y-4 #w-[30%] #text-[12px]">
          <h4 class="#text-sm #font-semibold">Waar gebruiken wij deze foto voor?</h4>

          <div class="#space-y-2">
            <p class="#text-[14px] #font-medium">Intern</p>
            <ul class="#list-disc #pl-5 #space-y-1 #text-[11px]">
              <li>Werktijden</li>
              <li>Medewerker van de maand</li>
              <li>In de kantine voor verjaardagen en voorstellen</li>
            </ul>
          </div>

          <div class="#space-y-2">
            <p class="#text-[14px] #font-medium">Extern</p>
            <ul class="#list-disc #pl-5 #space-y-1 #text-[11px]">
              <li>Admin automatisch klantcontact na afronden en inpakken</li>
              <li>Op de website medewerkerspagina</li>
            </ul>
          </div>

          <p class="#mt-4 #text-[12px] #text-gray-600">
            Wil je niet dat jouw naam en foto op de website komen? Bespreek dit dan met HR.
          </p>
        </div>
        <div>
          <canvas ref="canvas" class="#m-auto #max-w-full"></canvas>
          <div v-if="!processing" class="#mt-4 #flex #space-x-2 #justify-center">
            <Button
              text="Opnieuw"
              btn-class="secondary"
              icon="arrows-rotate"
              @click="photoTaken = false"
            />
            <Button
              :text="uploading ? 'Bezig met opslaan' : 'Insturen'"
              icon="circle-check"
              :disabled="uploading"
              @click="uploadPhoto()"
            />
          </div>
          <div v-else class="#my-4 #flex #justify-center">
            <LoadingIndicator />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../components/Button.vue";
import SvgUse from "../components/SvgUse.vue";
import { mapGetters } from "vuex";
import scanner from "../mixins/scanner";
import FlashMessage from "../components/FlashMessage.vue";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import Modal from "../../admin/components/Modal.vue";

export default {
  components: { Button, SvgUse, LoadingIndicator, FlashMessage, Modal },
  mixins: [scanner],
  data() {
    return {
      canvas: null,
      canvasFaceFrame: null,
      countDown: 7,
      countingDown: false,
      examplePhotos: [],
      interval: null,
      open: false,
      processing: false,
      uploading: false,
      employee: null,
      message: false,
      photoTaken: false,
      steps: [
        { title: "Lampen", content: "Staan de lampen aan op volle belichting?", completed: false },
        {
          title: "Kader",
          content:
            "Plaats jouw kin onderaan de rode rechthoek en de bovenkant van jouw hoofd bovenaan de rode rechthoek. Pas eventueel de stoel aan in hoogte",
          completed: false,
        },
        { title: "Kijk in de camera", content: "Een glimlach is altijd leuker", completed: false },
        {
          title: "Voorbeelden",
          content: "Kijk naar de voorbeelden voor de perfecte verhouding",
          completed: false,
        },
      ],
      video: null,
    };
  },
  computed: {
    ...mapGetters(["route", "isAdminLevel"]),
  },
  async mounted() {
    this.video = this.$refs.video;
    this.canvas = this.$refs.canvas;
    this.canvasFaceFrame = this.$refs.canvasFaceFrame;

    this.startCapture();
    this.getExamplePhotos();
  },
  methods: {
    async onRfidScan(rfid) {
      const response = await fetch(this.route("photobooth.employee"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          rfid: rfid,
        }),
      });

      if (response.ok) {
        this.employee = await response.json();
        this.open = true;

        this.waitForVideoDimensions().then(() => {
          this.drawFaceFrameOnCanvas();
        });
      } else {
        await response.text();
      }
    },
    waitForVideoDimensions() {
      return new Promise((resolve) => {
        const checkDimensions = () => {
          if (this.video.videoWidth && this.video.videoHeight) {
            resolve();
          } else {
            setTimeout(checkDimensions, 100);
          }
        };
        checkDimensions();
      });
    },
    drawFaceFrameOnCanvas() {
      if (!this.video || !this.canvasFaceFrame) return;

      this.canvasFaceFrame.setAttribute("width", this.video.clientWidth);
      this.canvasFaceFrame.setAttribute("height", this.video.clientHeight);

      const ctx = this.canvasFaceFrame.getContext("2d");

      ctx.clearRect(0, 0, this.canvasFaceFrame.width, this.canvasFaceFrame.height);

      const rectWidth = 150;
      const rectHeight = 180;

      const x = (this.video.clientWidth - rectWidth) / 2;
      const y = 20;

      ctx.strokeStyle = "red";
      ctx.lineWidth = 2;
      ctx.strokeRect(x, y, rectWidth, rectHeight);
    },
    async uploadPhoto() {
      this.uploading = true;

      const dataUrl = this.canvas.toDataURL("image/png");
      const file = this.dataURLtoFile(dataUrl, "image.png");

      const formData = new FormData();
      formData.append("image", file);
      formData.append("employee_id", this.employee.id);

      const response = await fetch(this.route("photobooth.upload"), {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        this.$refs.message.flash(await response.json(), "success", 4000);
      } else {
        this.$refs.message.flash(JSON.parse(await response.text()), "error", 4000);
      }
      this.message = true;

      this.reset();
    },
    reset() {
      this.uploading = false;
      this.photoTaken = false;
      this.employee = null;
      this.steps.forEach((step) => {
        step.completed = false;
      });
    },
    initCanvas() {
      this.canvas.setAttribute("width", this.video.videoWidth);
      this.canvas.setAttribute("height", this.video.videoHeight);
    },
    async startCapture() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: { ideal: 3840 },
            height: { ideal: 2160 },
          },
          audio: false,
        });
        this.video.srcObject = stream;
        this.video.play();
      } catch (error) {
        console.error("Error accessing webcam:", error);
        alert(error);
      }
    },
    startCountdown() {
      this.countingDown = true;

      const interval = setInterval(() => {
        if (this.countDown > 1) {
          this.countDown--;
        } else {
          clearInterval(interval);
          this.takePhoto();
          this.countDown = 7;
          this.countingDown = false;
        }
      }, 1000);
    },
    async takePhoto() {
      this.photoTaken = true;

      const context = this.canvas.getContext("2d");
      context.drawImage(this.video, 0, 0, this.video.videoWidth, this.video.videoHeight);

      this.processing = true;

      const dataUrl = this.canvas.toDataURL("image/png");
      const file = this.dataURLtoFile(dataUrl, "image.png");

      const formData = new FormData();
      formData.append("image", file);

      const response = await fetch(this.route("photobooth.preview"), {
        method: "POST",
        body: formData,
      });

      const imageBlob = await response.blob();

      const img = new Image();
      img.src = URL.createObjectURL(imageBlob);

      img.onload = () => {
        context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        context.drawImage(img, 0, 0, this.canvas.width, this.canvas.height);
        URL.revokeObjectURL(img.src);
      };

      this.processing = false;
    },
    async getExamplePhotos() {
      const response = await fetch(this.route("photobooth.examples"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.ok) {
        this.examplePhotos = await response.json();
      } else if (!response.ok) {
        await response.text();
      }
    },
    dataURLtoFile(dataUrl, file) {
      var arr = dataUrl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], file, { type: mime });
    },
    toggleStep(index) {
      this.steps[index].completed = !this.steps[index].completed;
    },
  },
};
</script>
