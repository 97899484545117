class BaseError extends Error {
  constructor(message, settings = {}) {
    super(message);

    this.id = Symbol();
    this.type = this.constructor.name;
    this.key = settings.key ?? null;
    this.section = settings.section ?? null;
    this.cta_text = settings.cta_text ?? null;
    this.cta = settings.cta ?? null;
    this.cta_position = settings.cta_position ?? "end";
  }

  toJSON() {
    return {
      type: this.type,
      key: this.key,
      message: this.message,
      section: this.section,
      cta_text: this.cta_text,
      cta: this.cta,
      cta_position: this.cta_position,
    };
  }
}

export class FatalFormError extends BaseError {
  constructor(message, settings = {}) {
    super(message, settings);

    window.emitter.emit("show-hide-preview", "hide");
  }
}

export class InfoFormWarning extends BaseError {
  constructor(message, settings = {}) {
    super(message, settings);
  }
}
